export const data = [
  {
    title: "Blockchain",
    description: "Simple blockchain implementation and CLI based on the Bitcoin spec written in Go",
    date: "2021-06-01",
    tools: [
      "Go",
      "NutsDB",
      "Bitcoin",
    ],
    repoPath: "https://github.com/neil-berg/blockchain",
    hasPost: false,
  },
  {
    title: "Chef",
    description: "Go, Postgres, and React/TypeScript app for managing your recipes",
    date: "2021-03-03",
    tools: [
      "Go",
      "Gorm",
      "Postgres",
      "TypeScript",
      "React",
      "Redux",
      "Styled components"
    ],
    repoPath: "https://github.com/neil-berg/chef",
    hasPost: false,
  },
  {
    title: "Bull",
    description: "Node, Mongo, and React/TypeScript app to track stocks and financial news",
    date: "2020-11-03",
    tools: [
      "Finnhub API",
      "TypeScript",
      "Express",
      "MongoDB",
      "Mongoose",
      "JWTs",
      "React",
      "Styled components"
    ],
    repoPath: "https://github.com/neil-berg/bull",
    hasPost: false,
  },
  {
    title: "Cork",
    description: "Share and discover natural wines with this Node, Mongo, and React app",
    date: "2019-10-21",
    tools: [
      "Nodejs",
      "Express",
      "MongoDB",
      "Mongoose",
      "BcryptJS",
      "JWTs",
      "Sharp",
      "Multer",
      "React",
      "React-spring",
      "Styled components"
    ],
    path: "https://cork.herokuapp.com",
    repoPath: "https://github.com/neil-berg/cork",
    hasPost: true,
  },
  {
    title: "Erica Mahinay",
    description: "React (Gatsby) artist portfolio using Contentful as the CMS",
    date: "2019-09-22",
    tools: [
      "React",
      "Gatsby",
      "GraphQL",
      "Contentful",
      "React-spring",
      "Nuka carousel",
      "Styled components",
    ],
    path: "https://ericamahinay.studio",
    repoPath: "https://github.com/neil-berg/erica-mahinay",
    hasPost: true,
  },
  {
    title: "Emanuel Röhss",
    description: "Artist portfolio built in React (Gatsby) and Airtable",
    date: "2019-07-27",
    tools: [
      "React",
      "Gatsby",
      "GraphQL",
      "Airtable",
      "React-spring",
      "React-swipe",
      "Styled components",
    ],
    path: "https://emanuelrohss.com",
    repoPath: "https://github.com/neil-berg/emanuel-rohss",
    hasPost: true,
  },
  {
    title: "Chow Now",
    description: "React-based restaurant finder using the Yelp API",
    date: "2019-06-19",
    tools: [
      "React",
      "Yelp Fusion API",
      "Jest",
      "React Testing Library",
      "Leaflet",
      "Styled components",
      "Axios",
      "React-router",
    ],
    path: "https://chow-now.netlify.com/",
    repoPath: "https://github.com/neil-berg/Yelp-Restaurants",
    hasPost: true,
  },
  {
    title: "Movie Mania",
    description: "React/Redux app to search for movies using the Movie DB API",
    date: "2019-05-03",
    tools: [
      "React",
      "Redux",
      "Movie DB API",
      "Styled components",
      "Axios",
      "React-router",
    ],
    path: "https://movie-mania.netlify.com/",
    repoPath: "https://github.com/neil-berg/movie-mania",
    hasPost: true,
  },
  {
    title: "News Flash",
    description: "React and Firebase app to search and save New York Times articles",
    date: "2019-04-19",
    tools: [
      "React",
      "Firebase",
      "OAuth2",
      "NYT API",
      "Styled components",
      "React-router",
    ],
    path: "https://news-flash.netlify.com/",
    repoPath: "https://github.com/neil-berg/nyt-articles",
    hasPost: true,
  },
  {
    title: "Meredith Lackey",
    description: "Artist portfolio written in React",
    date: "2019-02-07",
    tools: ["React", "Styled components", "React-router", "Netlify"],
    path: "https://mlackey-demo.netlify.com/",
    repoPath: "https://github.com/neil-berg/mlackey",
    hasPost: true,
  },
]
